<template>
  <CustomerServiceOverview />
</template>

<script>
import CustomerServiceOverview from "@/esf_utrecht_bankxl/core/components/customerservice/CustomerServiceOverview.vue";
export default {
  name: "CustomerServiceOverviewView",
  components: {
    CustomerServiceOverview,
  },
};
</script>
