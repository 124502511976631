<template>
  <section class="blog-page" :class="{ 'navbar--hidden': !showNavbar }">
    <b-container>
      <Breadcrumbs class="blog-navbar-sticky" />
      <div class="static pt-7 blog-list">
        <div class="p-0">
          <b-row class="mob-show m-0">
            <b-col cols="12" xl="3" lg="3" md="3" class="p-0">
              <ClientOnly>
                <VueSlickCarousel
                  v-if="blogBanner"
                  :arrows="false"
                  :dots="false"
                  :slidesToShow="1"
                >
                  <div
                    class="cms-block-content"
                    v-for="(slide, index) in blogBanner.slides"
                    :key="index"
                  >
                    <div
                      class="mobile-banner"
                      :style="`background-image: url('${slide.media.url}');`"
                    ></div>
                  </div>
                </VueSlickCarousel>
              </ClientOnly>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-row>
            <b-col cols="12" xl="3" lg="3" md="4" class="sticky-mobile">
              <div class="blog-content-wrap">
                <h4 class="blog-title">{{ $t("CustomerService") }}</h4>
                <div class="blog-search">
                  <b-form-input
                    type="text"
                    :placeholder="$t('search_customer_service')"
                    class="search-input"
                    @keyup="searchBlog"
                  ></b-form-input>
                  <div class="search-btn">
                    <i class="fa fa-search"></i>
                  </div>
                </div>
                <ul class="filter-list">
                  <li
                    :class="{ active: activeCategory === null }"
                    @click="$router.push({ name: 'klantenserviceberichten' })"
                  >
                    Alles
                  </li>
                  <li
                    v-for="category of blogCategories"
                    :key="category.category_id"
                    @click="
                      $router.push(
                        `/klantenservice/categorie/${category.identifier}/`
                      )
                    "
                    :class="{
                      active:
                        activeCategory &&
                        activeCategory.identifier === category.identifier,
                    }"
                  >
                    {{ category.title }}
                  </li>
                </ul>
              </div>
            </b-col>
            <b-col cols="12" xl="9" lg="9" md="8">
              <b-row class="desktop-show">
                <b-col>
                  <ClientOnly>
                    <VueSlickCarousel
                      v-if="blogBanner"
                      :arrows="false"
                      :dots="false"
                      :slidesToShow="1"
                    >
                      <div
                        class="cms-block-content"
                        v-for="(slide, index) in blogBanner.slides"
                        :key="index"
                      >
                        <div
                          class="blog-banner"
                          :style="`background-image: url('${slide.media.url}');`"
                        ></div>
                      </div>
                    </VueSlickCarousel>
                  </ClientOnly>
                </b-col>
              </b-row>
              <div class="blog-grid">
                <div class="blog-sorting">
                  <div class="select-wrapper">
                    <b-form-select
                      id="sorting"
                      v-model="sorting"
                      :options="blogSortingOption"
                      class="select"
                    />
                  </div>
                </div>
                <b-row>
                  <div v-if="isLoading" class="box">
                    <div class="loader"></div>
                  </div>

                  <template v-else-if="!isFilterActive && allBlogs.length > 0">
                    <b-col
                      v-for="(item, index) in allBlogs"
                      :key="index"
                      cols="12"
                      xl="4"
                      lg="4"
                      md="6"
                    >
                      <b-link
                        class=""
                        :to="'/klantenservice/' + item.identifier + '/'"
                      >
                        <div class="blog-box">
                          <div class="blog-featured-image">
                            <img
                              :src="item.featured_image"
                              :alt="item.featured_img_alt"
                            />
                          </div>
                          <div class="blog-info">
                            <h4 class="">{{ item.title }}</h4>
                            <div class="meta-description">
                              <div class="blog-date">
                                <i class="fas fa-calendar-alt"></i>
                                <span
                                  >{{ blogtDate(item.creation_time) }}
                                </span>
                              </div>
                            </div>
                            <div class="blog-desc">
                              <p v-html="item.short_filtered_content"></p>
                            </div>
                          </div>
                          <div class="blog-link">
                            {{ $t("show_more") }}
                          </div>
                        </div>
                      </b-link>
                    </b-col>
                  </template>
                  <template
                    v-else-if="isFilterActive && filteredBlogs.length > 0"
                  >
                    <b-col
                      v-for="(item, index) in filteredBlogs"
                      :key="index"
                      cols="12"
                      xl="4"
                      lg="4"
                      md="6"
                    >
                      <b-link
                        class=""
                        :to="'/klantenservice/' + item.identifier + '/'"
                      >
                        <div class="blog-box">
                          <div class="blog-featured-image">
                            <img
                              :src="item.featured_image"
                              :alt="item.featured_img_alt"
                            />
                          </div>
                          <div class="blog-info">
                            <h4 class="">{{ item.title }}</h4>
                            <div class="meta-description">
                              <div class="blog-date">
                                <i class="fas fa-calendar-alt"></i>
                                <span>{{ blogtDate(item.creation_time) }}</span>
                              </div>
                            </div>
                            <div class="blog-desc">
                              <p v-html="item.short_filtered_content"></p>
                            </div>
                          </div>
                          <div class="blog-link">
                            {{ $t("show_more") }}
                          </div>
                        </div>
                      </b-link>
                    </b-col>
                  </template>
                  <div class="no-data-found" v-else>
                    <p>{{ $t("no_posts_found") }}</p>
                  </div>
                </b-row>
                <div class="load-more" v-if="showLoadMoreBtn">
                  <div @click="loadMore">
                    {{ $t("load_more_blog") }}
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
  </section>
</template>

<script>
import Breadcrumbs from "@/esf_utrecht_bankxl/core/components/core/Breadcrumbs.vue";
import config from "@config";
import ClientOnly from "vue-client-only";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { Logger } from "@storefront/core/lib/logger";
import { isServer } from "@storefront/core/helpers";

export default {
  name: "CustomerServiceOverview",
  components: {
    Breadcrumbs,
    VueSlickCarousel,
    ClientOnly,
  },
  mounted() {
    const bcrumb = {
      current: "Blog",
      routes: [],
    };

    if (!isServer) {
      window.addEventListener("scroll", this.onScroll);
    }

    this.$store.commit("breadcrumbs/set", bcrumb);
    this.blogsPerPage =
      this.$store.getters["blog_additional/getCustomerServiceBlogsPerPage"];
  },

  data() {
    return {
      isLoading: false,
      isFilterActive: false,
      activeCategory: null,
      sorting: "newest",
      searchTimer: null,
      allBlogs: [],
      filteredBlogs: [],
      currPage: 1,
      blogsPerPage: 9,
      showNavbar: true,
      lastScrollPosition: 0,
    };
  },
  computed: {
    currentPageNr: {
      get() {
        return this.$store.getters[
          "blog_additional/getCustomerServiceCurrentPageNr"
        ];
      },
      async set(val) {
        this.$store.dispatch("blog_additional/goToCustomerServicePage", {
          pageNr: val,
        });
        this.$store.commit(
          "blog_additional/setCustomerServiceBlogsPerPage",
          this.perPageVal
        );
      },
    },
    totalBlogs() {
      return this.$store.getters[
        "blog_additional/getTotalCustomerServiceBlogs"
      ];
    },
    currentPage() {
      return this.$store.getters[
        "blog_additional/getCurrentCustomerServicePage"
      ];
    },
    getTotalPages() {
      return this.$store.getters[
        "blog_additional/getTotalCustomerServicePages"
      ];
    },
    blogCategories() {
      var categories =
        this.$store.getters["blog_additional/getCustomerServiceCategories"];
      return categories;
    },
    allFilteredBlogs() {
      return this.$store.getters[
        "blog_additional/getFilteredCustomerServiceBlogs"
      ];
    },

    dateFormat() {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return options;
    },
    blogBanner() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "customer_service_banner"
      );
    },
    blogSortingOption() {
      const sortOpt = [];
      config.blogSortingOption.forEach((element) => {
        const option = {
          value: element,
          text: this.$t(`blogSortingOption.${element}`),
        };
        sortOpt.push(option);
      });
      return sortOpt;
    },
    showLoadMoreBtn() {
      if (this.isFilterActive) {
        return this.allFilteredBlogs.length > this.filteredBlogs.length;
      } else {
        return this.totalBlogs > this.currPage * this.blogsPerPage;
      }
    },
  },

  methods: {
    onScroll() {
      const currentScrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
    clearSearch(category) {
      this.searchKey = "";
      if (category == "alles") {
        this.isFilterActive = false;
        this.setAllBlogs();
      } else {
        this.activeCategory = category;
        this.getCustomerServiceBlogsByCategoryId(category.identifier);
      }
    },
    getCategoryLink(category) {
      return "/klantenservice/categorie/" + category;
    },
    async changePerPageVal() {
      this.$store.commit(
        "blog_additional/setCustomerServiceBlogsPerPage",
        this.perPageVal
      );
      await this.$store.dispatch("blog_additional/loadCustomerService");
    },
    setAllBlogs() {
      const list =
        this.$store.getters["blog_additional/getCustomerServiceList"];
      this.allBlogs = [];
      list.forEach((element) => {
        element.items.forEach((item) => {
          const isExist = this.allBlogs.find(
            (el) => el.post_id === item.post_id
          );
          const currentLength = this.currPage * this.blogsPerPage;
          if (!isExist && this.allBlogs.length < currentLength) {
            this.allBlogs = [...this.allBlogs, item];
          }
        });
      });
      this.sortBlogs();
      this.setBreadCrumbs();
    },
    getAllBlogs() {
      this.currPage = 1;
      this.isFilterActive = false;
      this.activeCategory = null;
      this.setAllBlogs();
    },
    async getAllBlogsCategory() {
      this.isLoading = true;
      await this.$store.dispatch("blog_additional/blogCategories");
      this.isLoading = false;
    },
    async getBlogsByCategoryId(identifier) {
      const category = this.blogCategories.find(
        (item) => item.identifier === identifier
      );
      Logger.debug("getBlogsByCategoryId", "CSO", category)();
      if (category != null) {
        const categoryId = category.category_id;
        this.activeCategory = category;
        this.isFilterActive = true;

        this.isLoading = true;
        await this.$store.dispatch(
          "blog_additional/loadCustomerServiceBlogsByFilter",
          {
            categoryId,
          }
        );
        this.isLoading = false;

        this.currPage = 1;
        this.filteredBlogs = this.allFilteredBlogs.slice(0, this.blogsPerPage);

        this.sortBlogs();
        this.setBreadCrumbs();
      }
    },
    async loadMore() {
      if (!this.isFilterActive) {
        this.currPage++;
        if (this.currPage * this.blogsPerPage > this.allBlogs.length) {
          await this.$store.dispatch(
            "blog_additional/goToCustomerServicePage",
            {
              pageNr: this.currPage,
            }
          );
        }
        this.setAllBlogs();
      } else {
        this.filteredBlogs = this.allFilteredBlogs.slice(
          0,
          this.filteredBlogs.length + this.blogsPerPage
        );
      }
      this.sortBlogs();
    },
    async searchBlog(event) {
      if (this.searchTimer != null) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(async () => {
        const searchVal = event.target.value;
        if (searchVal.length > 0) {
          this.isFilterActive = true;

          let payload = { searchVal };

          if (this.activeCategory !== null) {
            const categoryId = this.activeCategory.category_id;
            payload = { ...payload, categoryId };
          }

          this.isLoading = true;
          await this.$store.dispatch(
            "blog_additional/loadCustomerServiceBlogsByFilter",
            payload
          );
          this.isLoading = false;

          this.filteredBlogs = this.allFilteredBlogs.slice(
            0,
            this.blogsPerPage
          );
        } else {
          if (this.activeCategory !== null) {
            this.getBlogsByCategoryId(this.activeCategory.identifier);
          } else {
            this.isFilterActive = false;
            this.setAllBlogs();
          }
        }
      }, 300);
    },
    sortBlogs() {
      if (!this.isFilterActive) {
        const blogs = [...this.allBlogs];
        blogs.sort((a, b) => {
          if (this.sorting == "newest") {
            if (a.creation_time > b.creation_time) {
              return -1;
            }
            if (a.creation_time < b.creation_time) {
              return 1;
            }
          } else if (this.sorting == "oldest") {
            if (a.creation_time < b.creation_time) {
              return -1;
            }
            if (a.creation_time > b.creation_time) {
              return 1;
            }
          }
          return 0;
        });
        this.allBlogs = blogs;
      } else {
        const blogs = [...this.filteredBlogs];
        blogs.sort((a, b) => {
          if (this.sorting == "newest") {
            if (a.creation_time > b.creation_time) {
              return -1;
            }
            if (a.creation_time < b.creation_time) {
              return 1;
            }
          } else if (this.sorting == "oldest") {
            if (a.creation_time < b.creation_time) {
              return -1;
            }
            if (a.creation_time > b.creation_time) {
              return 1;
            }
          }
          return 0;
        });
        this.filteredBlogs = blogs;
      }
    },
    setBreadCrumbs() {
      let activeCategoryName = "Alles";

      if (this.activeCategory != null) {
        activeCategoryName = this.activeCategory.title;
      }

      const bcrumb = {
        current: activeCategoryName,
        routes: [
          { name: this.$t("CustomerService"), route_link: "/klantenservice/" },
        ],
      };
      this.$store.commit("breadcrumbs/set", bcrumb);
    },
    blogtDate(date) {
      let formattedDate = new Date(date).toLocaleDateString(
        "nl-NL",
        this.dateFormat
      );
      return formattedDate;
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_title
            : "Klantenservice",
        },
        {
          name: "keywords",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_keywords
            : "Klantenservice",
        },
        {
          name: "description",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_description
            : "Lijst van klantenserviceberichten",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.cmsDefaultPageContent
        ? this.cmsDefaultPageContent.meta_title
        : "Klantenservice",
      // all titles will be injected into this template
      link: [
        {
          rel: "canonical",
          href: config.app_url + this.$route.fullPath,
        },
      ],
    };
  },
  async created() {
    await this.getAllBlogsCategory();

    const {
      name,
      params: { category_id },
    } = this.$route;

    if (name == "klantenservice-category" && category_id) {
      this.getBlogsByCategoryId(category_id);
    } else {
      this.setAllBlogs();
    }
  },
  beforeDestroy() {
    if (!isServer) {
      window.removeEventListener("scroll", this.onScroll);
    }
  },
  watch: {
    sorting(currValue, oldValue) {
      if (currValue != oldValue) {
        this.sortBlogs();
      }
    },
    $route(newVal) {
      const {
        params: { category_id },
      } = newVal;

      if (category_id) {
        this.getBlogsByCategoryId(category_id);
      } else {
        this.getAllBlogs();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.blog-page {
  @include phone-xl {
    .blog-grid {
      padding-top: 200px;
    }
    .blog-navbar-sticky {
      position: fixed;
      top: 93px;
      z-index: 2;
      background: #fff;
      width: 100%;
      transition: all 1s ease-out;
    }
    .sticky-mobile {
      @include phone-xl {
        position: fixed;
        top: 113px;
        left: 0;
        background-color: #fff;
        padding-top: 10px;
        z-index: 1;
        transition: all 1s ease-out;
      }
    }
    &.navbar--hidden {
      .blog-navbar-sticky {
        top: 0;
      }
      .sticky-mobile {
        @include phone-xl {
          top: 21px;
        }
      }
    }
  }
  @media (max-width: 430px) {
    .blog-grid {
      padding-top: 233px;
    }
  }
}
.blog-content-wrap {
  position: sticky;
  top: 190px;
  @include phone-xl {
    position: relative;
    top: unset;
  }
}
.navbar--hidden {
  .blog-content-wrap {
    position: sticky;
    top: 20px;
    @include phone-xl {
      position: relative;
      top: unset;
    }
  }
}
</style>
