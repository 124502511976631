<template>
  <CustomerServiceDetail />
</template>

<script>
import CustomerServiceDetail from "@/esf_utrecht_bankxl/core/components/customerservice/CustomerServiceDetail.vue";
export default {
  name: "CustomerServiceDetailView",
  components: {
    CustomerServiceDetail,
  },
};
</script>
